<template>
  <div id="app">
    <router-view v-if="isReload"/>
  </div>
</template>

<script>
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
    }
  },

  data() {
    return {
      isReload: true,
      cur_hash: null,
      iMessage: false,
    }
  },
  mounted() {
  },
  methods: {
    reload() {
      this.isReload = false;
      this.$nextTick(() => {
        this.isReload = true
      })

    },
  }
}
</script>
<style lang="scss">
.el-image-viewer__canvas img{
  margin: auto !important;
}
</style>

